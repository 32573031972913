<template>
  <kombat-chat
    id="kombatchat"
    theme="dark"
    site="fk"
    name="fkchat"
  ></kombat-chat>
</template>

<script>
export default {
  data() {
    return {
      user: {},
    }
  },
  mounted() {
    this.user = this.$store.state?.user
    console.log('mount FantasyKombat user')
    console.log(this.user.uid)
    console.log(this.user.displayName)
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        document.getElementById('kombatchat').setAttribute('userid', this.user.uid)
        document.getElementById('kombatchat').setAttribute('username', this.user.displayName)
      }
    }
  },
}
</script>
