<template>
  <Chat>
  </Chat>
</template>

<script>
import Chat from '@/views/components/Chat.vue'

export default {
  components: {
    Chat,
  },
  setup() {
    return {}
  },
}
</script>

<style lang="scss">
</style>
